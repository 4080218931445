.folder-path-parent{margin: 0;}
.folder-path-parent{text-align: left;}
.folder-name{cursor: pointer;font-size: 15px;display: inline-block;margin: 0 7.5px !important;}
.folder-path{height: 30px;margin-top: 30px;padding: 0 7.5px;}
.folder-path span{cursor: pointer;font-size: 15px;}
.folder-coaches-parent .coach-breadcrumb{display: block;font-size: 16px;padding: 0 7.5px;}

/* club-page */
/* .club-description-form{width: 650px;margin: 0 auto;box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);padding: 30px 20px; border-radius: 5px;} */
.club-description-form .modal-dialog{max-width: 460px;}
.club-description-form.delete-popup .modal-dialog{max-width: 400px;text-align: center;}
.club-description-form.delete-popup .modal-header{justify-content: center;}
.club-description-form label{width: 250px;}
.club-description-form input{width: 100%;font-size: 18px;padding: 10px;}
.club-description-form textarea{width: 100%;font-size: 18px;padding: 10px;}
.club-desc-row{margin: 0 0 15px;} 
.club-desc-row input{border: 1px solid #ccc;border-radius: 8px;width: 100%;font-size: 12px;} 
.club-desc-row textarea{border: 1px solid #ccc;font-size: 12px;}
.club-desc-row label{margin-bottom: 6px;font-size: 12px;}
.club-btn{margin: 0 0 0 auto;}  
.club-btn button{background: #0671DD;color: #fff;padding: 5px 28px;margin: 0 6px;border-radius: 40px;border: none;font-size: 16px;}  
.club-description-form button.selected{border-radius: 30px;background: #0671DD;color: #fff;border: 2px solid #0671DD;margin: 5px;padding: 4px 20px;font-weight: 600;}
.club-description-form button.default{border-radius: 30px;background: transparent;color: #0671DD;border: 2px solid #0671DD;margin: 5px;padding: 4px 20px;font-weight: 600;}
.club-description-form .modal-folder-icon{width: 24px;}
.club-description-form .sub-folder-name{height: auto;min-height: 50px;max-height: 150px;overflow: auto;}
.club-description-form .sub-folder-name::-webkit-scrollbar {width: 5px;border-radius: 5px;}
.club-description-form .sub-folder-name::-webkit-scrollbar-track {background: transparent;}
.club-description-form .sub-folder-name::-webkit-scrollbar-thumb {background: #0671DD;}
.menu-icon {background: none;border: none;padding: 0;}
.error{font-size: 12px;color: red}

/* responsive */

@media(max-width: 1024px){
    .main-right-section{
        padding: 20px;
    }
    /* .folder-parent{
        width: 211px;
    } */
    .folder-path-parent{
        margin: 70px 30px 30px;
    }
    .folder-group{
        overflow: auto;
        /* height: calc(100vh - 230px); */
    }
    .sidebar{
        z-index: 999999;
    }
    .sidebar:after {
        content: '';
        background: rgba(0,0,0,0.07);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        z-index: -1;
    }
    .main-right-section .main-right-header h3{
        margin: 0 0 0 10px;
    }
}
@media(max-width: 991px){
    .folder-parent{
        margin: 10px 25px;
        width: 240px;
    }
    .folder-group{
        text-align: center;
    }
    .folder-group .folder-parent{
        text-align: left;
    }
    .main-right-section .main-right-header h3{
        margin: 0;
    }
}
@media(max-width: 767px){
    .folder-parent{
        margin: 10px 0;
        width: 100%;
        text-align: left;
    }
    .folder-path{
        font-size: 13px;
    }
    .folder-group{
        /* height: calc(100vh - 240px); */
        text-align: center;
        overflow-x: hidden;
    }
    .folder-group .folder-parent{
        /* height: calc(100vh - 240px); */
        text-align: left;
    }
    .main-right-section .main-right-header .touch-tab{
        position: absolute;
        top: 70px;
        left: calc(50% - 99px);
        width: 180px;
    }
    .main-right-section .main-right-header .touch-tab button{
        width: 90px;
        font-size: 12px;
        padding: 10px 6px;
    }
    .main-right-section .main-right-header h3{
        font-size: 17px;
    }
    .main-right-section .main-right-header h3 svg{
        width: 12px;
    }
    .add-folder{
        padding: 4px 8px;
        font-size: 12px;
    }
    .club-description-form label{
        width: 180px;
        font-size: 14px;
    }
    .club-description-form input,
    .club-description-form textarea{
        font-size: 12px;
        padding: 5px;
    }
    .club-description-form .modal-dialog,
    .folder-modal .modal-dialog{
        max-width: 340px;
    }
    .club-desc-row{
        margin: 0 0 20px;
    }
    .club-description-form .modal-title,
    .folder-modal .modal-title{
        font-size: 18px;
    }
    
}

.club-description-form.delete-popup .modal-dialog .modal-content{padding: 0;}
.club-description-form.delete-popup .modal-dialog .modal-content .modal-header{background: #0671DD; padding: 5px 16px;}
.club-description-form.delete-popup .modal-dialog .modal-content .modal-header .btn-close{position: absolute; left: 10px; filter: invert(1); opacity: 1;display: block !important;}
.club-description-form.delete-popup .modal-dialog .modal-content .modal-header .modal-title{color: #fff;}
.club-description-form.delete-popup .modal-dialog .modal-content .modal-footer{justify-content: center;}
.club-description-form.delete-popup .modal-dialog .modal-content .modal-footer .btn{max-width: 70px; width: 70px;}