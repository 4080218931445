.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    justify-content: center;
    align-items:center;
    background: rgba(0,0,0,0.3);
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: .75rem;
    border-top: 0px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}
.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem;
    border-bottom: 0px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    background: #0671dd;
    padding: 5px 16px;
    color: #fff;
    margin-bottom: 30px;
}
.modal .modal-dialog .modal-content .modal-header .modal-title{
    color: #fff;
}
.modal-dialog{
    width: 100%;
    max-width: 460px;
}
.modal .modal-dialog .modal-content{
    padding: 0;
}
.modal-content {
    padding: 0;
}
.modal-body {

    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
font-family: var(--bs-font-sans-serif);
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
position: relative;
flex: 1 1 auto;
padding: 1rem;
margin-top: 0;
}
.modal-footer .logout{
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}





