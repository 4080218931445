.folder-parent{position: relative;display: inline-block;box-shadow: 0 0 4px 0 rgb(0 0 0 / 30%);padding: 15px 0 10px;border-radius: 5px;margin: 15px 7.5px;width: 240px;cursor: pointer;background: #e6e9f2; transition: .3s ease;}
.folder-parent:hover{background: #ebf0ff;}
.folder-parent img{width: 70px;display: block;margin: 0 0 90px;padding: 0 10px}
.folder-parent .created-name{width: 100%; padding: 0 10px;font-size: 10px;color: #746262b3;}
.folder-parent p{font-size: 13px;margin: 0;color: #0671dd;padding-right: 20px;text-overflow: ellipsis;white-space: nowrap;}
.folder-parent p.foldername{font-size: 13px;margin: 0;color: #0671dd;padding-right: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.folder-parent div p{width: 90%;}
.folder-group{margin: 0 0 30px;}
.share-folder{position: absolute;right: 10px;bottom: 8px;cursor:pointer;padding: 0 !important;}
.share-folder svg{width: 20px;height: 20px;fill: #6c6a6a;margin: 0 0 0 7px;}
.disable{pointer-events: none;}
.folder-parent .blank-div{height: 15px;}

.folder-coaches-parent .folder-parent > div >img{padding: 0; width: 60px; height: 60px; border-radius: 50%; border: 1px solid #0671DD; margin-left: 10px;}


/* Folder code */ 
/*.folder-parent, .main-right-section .main-right-inner-inner .main-inner-block{background-color: #e0eeff; border-radius: 12px; transition: .2s; font-size: 14px; box-shadow: none; margin: 15px 10px; width: calc(20% - 20px); overflow: unset; max-width: 210px; min-width: 180px; padding: 10px;}
.folder-parent:hover/*, .main-right-section .main-right-inner-inner .main-inner-block:hover{background-color: #fff; box-shadow: 0 2px 10px 0 #e7e7e7; transform: scale(1.04);}*/
/* .folder-parent .folderImg{margin: 0 auto 20px !important;}
.folder-parent .created-name{text-align: center;} */
/* .main-inner-block{position: relative;}

.main-inner-block .three-dotIcon{position: absolute; height: 100%; width: 100%; top: 0; left: 0; right: 0; padding: 0;  line-height: 1; color: #6c6a6a; background-color: transparent; z-index: 2;}

.main-inner-block .three-dotIcon .btn-action{position: absolute; top: 5px; left: auto; right: 5px; padding: 0; height: 30px; width: 30px; display: flex; align-items: center; justify-content: center; line-height: 1; color: #6c6a6a; opacity: 0; background-color: #fff; border-color: transparent;font-size: 18px; border-radius: 50%; transition: .2s; box-shadow: 0px 0px 0px 1px #e7e7e7;}

.main-inner-block .three-dotIcon .btn-action::before{content: '...';}
.main-inner-block:hover .three-dotIcon .btn-action{opacity: 1;}

.main-inner-block .three-dotIcon .pop-up-foractionbtn{position: absolute; left: auto; right: 0; top: 35px; background-color: #fff; display: flex; flex-direction: column; align-items: center; justify-content: center; width: auto; min-width: 100px; padding: 10px 0; border-radius: 12px; border: 1px solid #e7e7e7; box-shadow: 2px 2px 6px 0 #e7e7e7; height: 140px; overflow-y: auto; overflow-x: hidden; white-space: nowrap;}
.main-inner-block .three-dotIcon .pop-up-foractionbtn img, .main-inner-block .three-dotIcon .pop-up-foractionbtn .footer-switch svg{height: auto; width: auto; max-width: 20px; min-width: 20px; margin-right: 5px;}
.main-inner-block .three-dotIcon .pop-up-foractionbtn img, .main-inner-block .three-dotIcon .pop-up-foractionbtn .footer-switch{padding: 3px; width: 100%;}
.main-inner-block .three-dotIcon .pop-up-foractionbtn .footer-switch:hover{background-color: #e7e7e7;}
.main-inner-block .three-dotIcon .pop-up-foractionbtn .footer-switch span{display: flex; align-items: center;}
.main-inner-block .three-dotIcon .pop-up-foractionbtn .tooltip-parent .custom-tooltip{display: none !important;}

.main-right-inner-inner .bottom-block-section h4{text-align: center; padding-bottom: 0;}
.main-right-inner-inner .bottom-block-section h4 span{color: #000; font-weight: 400; font-size: 16px; width: 100%; text-align: center; line-height: 1.4;}
.main-right-section .main-right-inner-inner .main-inner-block .inner-image-section img{min-height: auto; max-height: initial; max-width: 100%; width: auto; min-width: auto; height: auto;}
.main-right-section .main-right-inner-inner .main-inner-block .inner-image-section{min-height: auto; border-radius: 10px;} */


.folder-parent{background-color: #fff; border-radius: 4px; transition: .2s; font-size: 14px; box-shadow: 0 0 0 .6px #0671DD; margin: 10px 7.5px; width: calc(100% - 15px); overflow: unset; max-width: 240px; min-width: 200px; padding: 12px 6px; transform-style: preserve-3d; display: flex; align-items: center;}
.folder-parent:hover{background-color: #e0eeff; transform: scale(1.04); box-shadow: 0 2px 10px 0 #e7e7e7, 0 0 0 .6px #c9c9c9;}
.folder-parent .folderImg{margin: 0 auto 10px !important; filter: invert(94%) sepia(87%) saturate(1055%) hue-rotate(179deg) brightness(100%) contrast(104%); transition: .2s; transform: none;}
.folder-parent .created-name{text-align: center;}
.folder-parent .sub-folder-parent-parent{border: none; padding: 0; margin: 0 auto; text-align: left; width: calc(100% - 70px);}
.folder-parent .sub-folder-parent-parent .foldername{border: none; padding: 0; margin: 0; text-align: left; color: #363636 !important; user-select: none; pointer-events: none; white-space: break-spaces; max-height: 40px; min-height: 40px;}
.folder-parent .sub-folder-parent-parent .foldername{color: #0671DD !important;}
.folder-path-parent{padding: 0 40px;}
.folder-path-parent .folder-group{padding: 20px 0px; border-bottom: 1px solid #e7e7e7; margin: 0 0 15px; display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: flex-start;}
.folder-path-parent .folder-path{margin-top: 20px; background-color: #fff; display: flex; align-items: center; border-radius: 20px; padding: 0 15px; box-shadow: 0 2px 10px 0 #e7e7e7, 0 0 0 .6px #e7e7e7;}
.folder-path-parent .folder-path span{font-size: 12px; font-weight: 700;}
.folder-parent .sub-folder-parent-parent .file-counter{font-size: 11px; display: block; color: #616161; font-weight: 400; margin-bottom: 0px;}

.main-right-section{padding: 0px 0px 20px 280px; background-color: #f8fafc;}
.main-right-section .main-header-section{border-bottom: 1px solid #e7e7e7; padding: 20px 40px; background-color: #fff; box-shadow: 0 4px 10px 0 #e7e7e7;}
.main-right-section .main-right-header{margin-bottom: 10px;}
.main-right-section .main-right-inner{margin-top: 20px;}
.main-right-section .main-right-inner-inner{padding: 10px 0px;}

.main-right-section .main-right-inner-inner .footer-switch img:hover, 
.main-right-section .main-right-inner-inner .footer-switch svg:hover{filter: invert(0%) sepia(87%) saturate(1055%) hue-rotate(179deg) brightness(85%) contrast(104%); transform: scale(1.1);}
.main-right-section .main-right-inner-inner .main-inner-block{cursor: pointer;}
.main-right-section .main-right-inner-inner .main-inner-block:hover{box-shadow: 0 2px 10px 0 #e7e7e7, 0 0 0 .6px #c9c9c9; }

.main-right-inner-parent-custom{padding: 0 40px;}
.folder-coaches-parent{padding: 20px 40px 0;}
.folder-coaches-parent .folder-coaches-parent-inner{display: flex; flex-wrap: wrap;}
.main-right-section{height: auto; min-height: 100%;}
.MuiPagination-root{position: fixed; background-color: #fff; padding: 20px; right: 0; box-shadow: 0 0 10px 0 #f0f0f0; z-index: 3;}
/* Folder code */


.main-right-section .main-right-inner-inner .main-inner-block .btn-ellipse{position: absolute; top: 10px; right: 10px; padding: 4px; display: flex; align-items: center; justify-content: center; border-radius: 4px; z-index: 2; transition: all .2s; opacity: 0; visibility: hidden; transform: scale(0); background-color: #fff; border-color: #fff;}
.main-right-section .main-right-inner-inner .main-inner-block:hover .btn-ellipse{transform: scale(1); opacity: 1; visibility: visible;}
.main-right-section .main-right-inner-inner .main-inner-block .btn-ellipse:hover{background-color: #0671DD; border-color: #0671DD;}
.main-right-section .main-right-inner-inner .main-inner-block .btn-ellipse svg{height: 16px; width: 16px; color: #0671DD;}
.main-right-section .main-right-inner-inner .main-inner-block .btn-ellipse svg path{
    fill: #0671DD;
}
.main-right-section .main-right-inner-inner .main-inner-block .btn-ellipse:hover svg,
.main-right-section .main-right-inner-inner .main-inner-block .btn-ellipse:hover svg path{color: #fff; fill: #fff;}
.dropdown-back-btn{font-size: 14px; color: #fff; font-weight: 400; padding: 0 6px; background-color: #0671DD; border-radius: 4px; margin-bottom: 10px; margin-left: 10px;}
.main-right-section .main-right-inner-inner .block-footer.download-modal{max-width: 200px;}
.main-right-section .main-right-inner-inner .block-footer.download-modal .download-parent{display: flex; align-items: flex-start; justify-content: center; width: 100%; flex-direction: column; padding: 0;}
.download-parent .downloadBtn{border: none; background-color: transparent; outline: none; box-shadow: none; display: flex; align-items: center; justify-content: flex-start; padding: 8px 16px; margin-bottom: 8px; width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.download-parent .downloadBtn:hover{background-color: #40576d12;}
.download-parent .downloadBtn img{max-width: 22px; height: auto; margin-right: 8px;}
.download-parent .downloadBtn p{font-size: 14px; color: #000; font-weight: 400; margin: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: calc(100% - 33px);}

.main-right-section .main-right-inner-inner .folder-parent.folder-add-parent{flex-direction: column !important; overflow: auto; height: 200px; padding: 0 !important;}
.main-right-section .main-right-inner-inner .folder-parent.folder-add-parent:hover{transform: none;}
.main-right-section .main-right-inner-inner .folder-parent.folder-add-parent .list-btns{display: flex; align-items: center; justify-content: flex-start; text-align: left; width: 100%; padding: 6px 12px;}
.main-right-section .main-right-inner-inner .folder-parent.folder-add-parent .list-btns:hover{background-color: #40576d12 !important;}
.main-right-section .main-right-inner-inner .folder-parent.folder-add-parent .list-btns img{margin: 0; max-width: 25px; height: auto; margin-right: 8px; padding: 0;}



/* Responsive */
@media (max-width: 1600px){
    .main-section {
        height: 100%;
    }
}
@media(max-width: 1400px){
    .main-right-section .main-right-inner-inner .main-inner-block{
        margin: 15px 7.5px 15px;
    }
}
@media (max-width: 1024px){
    .main-section .main-right-section {padding: 0;}
    .main-right-section .folder-path-parent {margin: 70px 0px 30px;}
}
@media(max-width: 991px){
    .main-right-section .folder-path-parent{margin-top: 30px;}
    .main-right-section .main-right-inner-parent-custom .main-right-inner-inner, .main-right-section .main-right-inner-inner{display: flex;}
    .folder-group .folder-parent, .folder-coaches-parent-inner .folder-parent{margin: 10px; width: calc(33.33% - 20px); max-width: calc(33.33% - 20px);}
}
@media(max-width: 767px){
    .main-right-section .main-header-section.main-header-section-custom{padding: 20px;}
    .main-right-section .main-header-section-custom .custom-right-header{flex-wrap: wrap;}
    .main-right-section .main-header-section-custom .custom-right-header .touch-tab{position: static;}
    .folder-group .folder-parent, .folder-coaches-parent-inner .folder-parent{width: calc(50% - 20px); max-width: calc(50% - 20px);}
    .main-right-inner-parent-custom, .folder-path-parent{padding: 0 20px;}
    .folder-coaches-parent{padding: 20px 20px 0;}
    .main-right-section .main-right-inner-inner .main-inner-block{max-width: 240px;}
    .main-header-section.main-header-section-custom .filter-select{top: auto; margin: 0;}
}
@media(max-width: 576px){
    .folder-group .folder-parent, .folder-coaches-parent-inner .folder-parent{width: calc(100% - 20px); max-width: calc(100% - 20px);}
    .main-right-section .main-right-inner-inner .main-inner-block{max-width: 100%;}
}
/* Responsive */

.folder-coaches-parent .folder-parent .folderImg{filter: none;}