html,
body,
#root{height: 100%;}
.main-section{padding: 0;height: 100%;position: relative;}
.main-right-section{height: 100%;}
/* sidebar */
.sidebar{max-width: 280px;width: 100%; text-align: left; padding: 20px 0;background: #1b3f88;height: 100vh;position: fixed;left: 0;}
.sidebar .top-sidebar{text-align: right;color: #0671DD; padding: 0 20px;z-index: 9999;}
.sidebar .top-sidebar svg{width: 25px;height: 25px; margin-bottom: 20px; fill: #0671DD}
.sidebar .sidebar-header{display: flex;align-items: center;padding: 0 0 50px;border-bottom: 1px solid #868686;margin: 0 20px}
.sidebar .sidebar-header img{width: 60px; height: 60px; border-radius: 50%;margin-right: 10px;z-index: 9999;border: 1px solid #0671DD}
.sidebar .sidebar-header .profile-detail{overflow: hidden;z-index: 999;margin-left: 20px;}
.sidebar .sidebar-header .profile-detail p{font-size: 12px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;margin: 0 0 4px;color: #0671DD;}
.sidebar .sidebar-header .profile-detail h4{font-size: 20px;margin: 0 0 10px;font-weight: bold;color: #0671DD;}
.sidebar .sidebar-header .profile-detail button{background: #f1c048;color: #1b3f88;border-radius: 20px;padding: 3px 20px;}
.sidebar .sidebar-header .profile-detail button:focus{box-shadow: none;}
.sidebar .sidebar-listing{position: relative;}
.sidebar .sidebar-listing .list-group{padding: 20px 0;}
.sidebar .sidebar-listing .list-group .list-group-item{background: transparent;color: #fff;border: none;padding: 10px 0;padding-left: 20px;font-weight: 600;cursor: pointer;}
.sidebar .sidebar-listing .list-group .list-group-item a{text-decoration: none;color: #fff;}
.sidebar .sidebar-listing .list-group .list-group-item svg{margin-right: 5px;}
.sidebar .sidebar-listing .list-group .list-group-item.active,
.sidebar .sidebar-listing .list-group .list-group-item:hover{background: #b3d1f096;}
.sidebar .sidebar-bg{background: url(../assets/img/swing.png) no-repeat;filter: blur(6px);width: 100%;height: 100vh;background-position: top;background-size: cover;position: absolute;top: 0;}
.sidebar .sidebar-overlay{width: 100%;height: 100vh;position: absolute;top: 0;background: rgba(89, 122, 222, 0.4);}
.sidebar .sidebar-footer{position: absolute;bottom: 10px;text-align: center;margin: 0 auto;width: 120px;max-width: 100%;left: 29%;}
.sidebar .sidebar-footer img{width: 100%; height: 1005;}
.add-folder{background: #fff;background-color: transparent !important;padding: 11px 24px;font-size: 13px;border-radius: 25px;color: #0671DD;border: 1px solid #dedede;font-weight: 600;}
.folder-modal .add-folder{margin-bottom: 10px;padding: 5px 11px;}
.folder-modal .add-folder:focus{background: #0671DD !important;outline: none;box-shadow: none !important;color: #fff;}
.add-folder:hover{box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);background: #0671DD !important;color: #fff;}
.rightfolder-header{width: 130px;}
.dropdown-parent {display: flex;float: right;margin-top: -10px;}
.dropdown-parent .dropdown{margin-left: 10px;}
.dropdown-parent .dropdown button.dropdown-toggle{background-color: #0671DD;border-color: #0671DD;border-radius: 40px;padding: 6px 20px;font-size: 14px;}
.dropdown-parent .dropdown.show .btn-primary.dropdown-toggle{background-color: #0671DD;border-color: #0671DD;border-radius: 40px;padding: 6px 20px;font-size: 14px;}
.dropdown-toggle::after{position: relative;top: 2px}
.img-uploader{position: relative;}
.img-uploader input{position: absolute;left: 20px;padding: 0 !important;opacity: 0;height: 33px;width: 126px !important;cursor: pointer;font-size: 0 !important;}

/* main-section */
.main-right-section{text-align: center;padding: 20px 40px 20px 320px;width: 100%;max-width: 100%;background: #fafafa;}
.main-right-section{font-size: 20px; color: #0671DD; font-weight: 600;}
.main-right-section .main-right-header{display: flex; justify-content: space-between;align-items: center;margin-bottom: 30px;}
.main-right-section .filter-btn-parent{width:125px;}
.main-right-section .main-right-header h3{font-weight: 600;margin: 0;}
.main-right-section .main-right-header h3 svg{cursor: pointer;width: 18px; margin-left: 10px;}
.main-right-section .loader-p{height: calc(100vh - 200px);display: flex;align-items: center;justify-content: center;margin: 0;width: 100%;}
.main-right-section .touch-tab{display:flex;border: 1px solid #dedede; border-radius: 50px;margin: 0 auto;width: auto;}
.main-right-section .touch-tab button {width:auto;border: none;border-radius: 50px;color: #0671DD;background: transparent;font-size: 12px;font-weight: 600;padding: 11px 24px; line-height: 14px;}
.main-right-section .touch-tab button.active {background: #0671DD;color: #fff; box-shadow: 2px 2px 5px 0 rgb(6 113 221 / 20%);}
.main-right-section .main-right-header .filter-btn{border: 1px solid #0671DD; color: #0671DD; padding: 10px 25px; border-radius:50px;background: transparent;font-weight: 600}
.main-right-section .main-right-header .filter-btn svg{height: 20px;width: 20px;margin-right: 5px;}
.main-right-section .main-right-inner{margin: 60px 0 15px;}
.main-right-section .folder-path-parent .main-right-inner {margin: 0;}
.main-right-section .main-right-inner-inner{text-align: left;display: flex;align-items: flex-start;flex-wrap: wrap;padding: 0;}
.main-right-section .main-right-inner-inner .main-inner-block{cursor: default;background-color: #fff;margin: 15px 7.5px 15px;border-radius: 10px;width: 240px;box-shadow: 0 8px 13px 0 rgb(224 229 238 / 50%);position: relative;overflow: visible; transition: .3s;}
.main-right-section .main-right-inner-inner .main-inner-block img{width: 100%;object-fit: cover;height: 100%;}
.main-right-section .main-right-inner-inner .cale-btn{background-color: #0671DD;display: flex;align-items: center;width: max-content;color: #FFFFFF;font-size: 10px;font-weight: 400;line-height: 15px;padding: 5px;margin: 7px 15px 0; cursor: default; box-shadow: none;}
.main-right-section .main-right-inner-inner .cale-btn svg{height: 15px;width: 14px;}
.main-right-section .main-right-inner-inner h4{display: flex;justify-content: space-between;/* font-size: 14px; *//* font-weight: 500; *//* line-height: 18px; */color: #222222;padding-bottom: 5px;padding: 10px 15px 10px;margin: 0;}
.main-right-section .main-right-inner-inner h4 span{overflow: hidden;text-overflow: ellipsis;font-size: 18px;min-height: 56px; cursor: pointer;}
.main-right-section .main-right-inner-inner .bottom-block-section p{color: #0671dd;font-size: 13px;line-height: 24px;padding: 0 15px;padding-bottom: 10px;font-weight: 300;margin: 0;}
.main-right-section .main-right-inner-inner .bottom-block-section p.created-by{font-size: 10px;font-weight: 600; text-align: left;}
.main-right-section .main-right-inner-inner .inner-image-section{border-radius: 20px 20px 0 0;height: 100px;}
.main-right-section .main-right-inner-inner .footer-switch{color: #9e9c9c; font-size: 11px;}
.main-right-section .main-right-inner-inner .footer-switch .form-check label{font-size: 9px;margin-top: 4px;}
.main-right-section .main-right-inner-inner .block-footer{display: flex;justify-content: space-between;align-items: center;border-top: 1px solid #DEDEDF;padding: 0px 15px;height: 30px}
.main-right-section .main-right-inner-inner .block-footer .footer-switch span{margin-left: 5px;}
.main-right-section .main-right-inner-inner .block-footer .footer-switch .move-folder{width: 20px;height: auto;cursor: pointer;}
.main-right-section .main-right-inner-inner .block-footer .form-switch .form-check-input{height: 14px;width: 30px;margin: 0 5px 0 4px;cursor: pointer;}
.main-right-section .main-right-inner-inner .block-footer .form-switch {padding: 0;display: flex;align-items: center;cursor: pointer;}
.main-right-section .main-right-inner-inner .block-footer .form-switch + p{padding: 0;font-size: 10px;color: #0671dd;line-height: 13px;}
.main-right-section .main-right-inner-inner .block-footer .footer-switch form{display: flex; align-items: center; justify-content: flex-start;}
.main-right-section .main-right-inner-inner h4 span{font-size: 12px;}
.main-right-section .main-right-inner-inner .block-footer .footer-icon svg{fill: #596c8b;width: 18px;height: 18px;cursor: pointer;}
.main-right-section .main-right-inner-inner .block-footer .footer-icon {display: flex;justify-content: flex-end;align-items: center;width: 100%;}
.main-right-section .main-right-inner-inner .block-footer .footer-icon a{margin: 0;}
/* .main-right-section .main-right-inner-inner .bottom-block-section{margin: 10px 15px;} */
.bottom-block-section button svg{margin-right: 5px;}
.bottom-block-section button span{vertical-align: middle;}
.main-right-inner-inner .bottom-block-section p{word-break: break-all; }
.main-right-inner-inner .bottom-block-section h4 span{word-break: break-all;font-size: 15px;font-weight: 700;color: #0671dd;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
.folder-modal .folder-input{width: 100%;border-radius: 5px;height: 40px;border: 1px solid #bfbfbf !important;padding: 10px;}
.folder-modal .modal-footer,
.folder-modal .modal-header,.download-modal .modal-header{border: none;}
.folder-modal .modal-footer{justify-content: center;}
.folder-modal .modal-header .modal-title img{width: 16px;}
.folder-modal .btn-close{display: none;}
.folder-modal .btn-primary{background: #0671DD;margin-bottom: 0;}
.folder-modal .back-folder{border-radius: 20px;padding: 3px 10px;margin-bottom: 10px;font-size: 12px;margin-top: -20px;color: #ffff;background: #0671DD;text-transform: capitalize;}
.folder-modal .modal-dialog ,.download-modal .modal-dialog{display: flex;justify-content: center;align-items: center;margin: 0 auto;height: 100%;}
.form-check-input:checked {background-color: #0671DD;border-color: #0671DD;}
.MuiPagination-root{position: absolute;bottom: 20px;right: 30px;}
.folder-coaches-parent{
    /* margin: 60px 0; */
    text-align: left;}
.list-btns{display: block;background: transparent !important;border: none;}
.list-btns img{width: 25px;margin-top: -3px;padding-right: 5px;}
.list.list-btns.active{background-color: #0671DD !important;color: #fff; border-radius: 6px; padding: 4px 6px; padding-left: 12px;}
.folder-add-parent{padding: 0 20px;}
.sub-folder-parent-parent{border-top: 1px solid #d5d2d2;padding: 5px 10px 0;margin-top: 5px;}
.sub-folder-parent-parent .share-folder{width: auto;display: flex;cursor: pointer}
.folder-add-parent .touch-tab{display:flex;border: 1px solid #dedede; border-radius: 50px;margin: 0 auto;width: 168px;}
.folder-add-parent .touch-tab button {width:auto;border: none;border-radius: 50px;color: #0671DD;background: transparent;font-size: 12px;font-weight: 600;padding: 6px 24px; transition: .3s ease-out;}
.folder-add-parent .touch-tab button.active {background: #0671DD;color: #fff; box-shadow: 2px 2px 5px 0 rgb(6 113 221 / 20%);}
.tooltip-parent{position: relative;}
.tooltip-parent:hover .custom-tooltip{display: block;}
.tooltip-parent .custom-tooltip{display: none;position: absolute;background: rgba(0,0,0,0.8);padding: 5px 7px;border-radius: 5px;top: -35px;white-space: nowrap;right: -5px;color: #fff;font-weight: normal;font-size: 11px;margin: 0 !important}
.tooltip-parent .custom-tooltip:before{content: '';position: absolute;border-left: 7px solid transparent;border-top: 7px solid rgba(0,0,0,0.8);border-right: 7px solid transparent;border-bottom: 7px solid transparent;top: 26px;right: 5px;}

/* Oct-05 */
.main-right-section .main-right-inner-inner .main-inner-block .inner-image-section img{transition: .4s ease-out;}
.main-right-section .main-right-inner-inner .main-inner-block .inner-image-section:hover img{ transform: scale(1.1);}
.main-right-section .main-right-inner-inner .main-inner-block .inner-image-section{min-height: 132px; height: auto; overflow: hidden; position: relative; border-radius: 10px 10px 0 0; cursor: pointer;}
.main-right-section .main-right-inner-inner .main-inner-block .inner-image-section::before{background-color: transparent; transition: .4s ease-out; content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2;}
.main-right-section .main-right-inner-inner .main-inner-block .inner-image-section:hover::before{background-color: #00000040;}
.main-right-section .main-right-inner-inner .main-inner-block .inner-image-section img{min-height: 132px; max-height: 132px;}
.main-right-section .main-right-inner-inner h4 span{min-height: auto;}
.dropdown-parent .dropdown button.dropdown-toggle{padding: 6.5px 20px; font-size: 12px !important; font-weight: 600;}
#dropdown-item-button + .dropdown-menu .dropdown-item{font-size: 12px;}
.folder-modal .save-btn{margin: .25rem;}
.folder-modal .back-folder{border: 1px solid #0671DD;}
.folder-modal .modal-footer{justify-content: flex-end;}
.modal .modal-dialog .modal-content{padding: 20px;}
.modal .modal-dialog .modal-content .folder-add-parent .touch-tab{margin-bottom: 10px;}
.modal-footer .btn{font-size: 13px; font-weight: 600;}
.modal .modal-dialog .modal-content .modal-header .modal-title{font-size:20px;color: #0671DD;}
.main-right-section .main-right-inner-inner .footer-switch{margin: 0 4px;}
.main-right-section .main-right-inner-inner .footer-switch:last-child{margin-right: 0;}
.main-right-section .main-right-inner-inner .footer-switch:first-child{margin-left: 0;}
.modal.download-modal .modal-dialog .modal-content .modal-body{display: flex; align-items: center; justify-content: space-around;}
.modal.download-modal .modal-dialog .modal-content .modal-body button{border: none; outline: none; box-shadow: none; text-align: center; background: transparent; font-size: 14px;}
.modal.download-modal .modal-dialog .modal-content .modal-body span.pdf-span, .modal.download-modal .image-span{ text-align: center; font-size: 14px; cursor: not-allowed; opacity: .6;}
.modal.download-modal .modal-dialog .modal-content .modal-body span.pdf-span img, .modal.download-modal .image-span img{
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;
}
.modal.download-modal .modal-dialog .modal-content .modal-body button img{ 
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;}
.modal.download-modal .modal-dialog .modal-content .modal-header{padding-bottom: 30px;}
.modal.download-modal .modal-dialog .modal-content .modal-body .active-pdf{
    color: #000;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
}
.modal.download-modal .modal-dialog .modal-content .modal-body .active-pdf img{
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;
}
.modal .modal-dialog .modal-content .folder-add-parent .list.list-btns{font-size: 14px;}
.folder-modal .save-btn, .folder-modal .modal-footer .btn{
    transition: .5s ;
    box-shadow: none;
}
.folder-modal .modal-footer .btn:hover{
    background-color: transparent;
    box-shadow: inset 0px -40px 0 0px #0671DD;
}

.folder-modal .save-btn:hover{
    background-color: transparent !important;
    border: 1px solid #0671DD;
    box-shadow: inset 0px -40px 0 0px #fff !important;
    color: #0671DD;
}
.folder-modal .modal-footer{
    padding-top: 20px;
}
.download-modal .modal-header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.download-modal .modal-header .btn-close{
    position: absolute;
    top: 20px;
    right: 20px;
    background-size: 12px;
}
.folder-modal .add-folder{font-size: 12px;}

.edit-popup .modal-footer .btn, .club-description-form .modal-footer .btn{
    margin: 4px;
}

.edit-popup .modal-dialog .modal-content .modal-body{
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: space-between;
}
.club-description-form .modal-body .club-desc-row.profile-box img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #0671DD;
}
.club-description-form .modal-body .club-desc-row.profile-box input{
    width: calc(100% - 70px);
    border: none;
}
.edit-popup .modal-dialog .modal-content .modal-body .club-desc-row{
    max-width: 50%;
    flex: 0 0 50%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
}
.edit-popup .modal-dialog .modal-content .modal-body .club-desc-row:first-child{
    max-width: 100%;
    flex:0 0 100%;
}
.club-description-form.edit-popup input{
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
}
.edit-popup .modal-dialog .modal-content .modal-body .club-desc-row .Dropdown-root{
    min-width: 154px;
    width: 100%;
}
.edit-popup .modal-dialog .modal-content .modal-body .club-desc-row .Dropdown-root .Dropdown-control{
    border-radius: 8px;
}
.club-description-form.edit-popup label{
    font-size: 13px;
    margin-bottom: 6px;
    width: auto;
    padding-left: 5px;
}


/* /Oct-05 */

@media (max-width: 1550px) {
    .main-right-section .main-right-inner-inner{text-align: center;}
}
@media (max-width: 1400px) {
    .main-right-section .main-right-inner-inner{text-align: center;}
    .main-right-section .main-right-inner-inner .main-inner-block{margin: 10px 25px;}
}
@media (max-width: 991px) {
    .main-right-section .main-right-inner-inner{display: inline-block;width: 100%;padding-top: 50px;}
    /* .main-right-section .main-right-inner-inner .main-inner-block{width: 43%;} */
    .main-right-section .touch-tab {top: 60px;margin: 0;}
}
@media (max-width: 767px) {
    .main-right-section .main-right-inner-inner{display: inline-block;width: 100%;padding: 0;}
    .main-right-section .main-right-inner-inner .main-inner-block{width: 100%;}
    .main-right-section .touch-tab {top: 60px;margin: 0;}
    .main-right-section .main-right-inner-inner .main-inner-block{margin: 10px 0;}
    .edit-popup .modal-dialog .modal-content .modal-body .club-desc-row{max-width: 100%;flex: 0 0 100%;}
    .folder-path-parent {margin: 140px 30px 30px;}
    .edit-popup .modal-dialog .modal-content .modal-body{padding: 0;}
    .edit-popup .modal-dialog{margin-top: 60px;}
    .dropdown-parent {display: flex;float: left;margin-top: 50px;width: 100%;justify-content: center;position: relative;}
    .main-right-section .main-right-inner{margin: 60px 0 55px;}
    .MuiPagination-root{bottom: 20px}
    .filter-select{max-width: 150px;margin: 10px auto;position: absolute;top: -60px;}
    .switch.btn.btn-xs{min-height: 30px;height: 32px}
    .folder-group .folder-parent{text-align: left;}
    .main-section{height: 100% !important;}
}

.sidebar svg.edit-profile-btn {
    height: 20px;
    width: 20px;
    display: block;
    opacity: 1;
    z-index: 9999999;
    fill: #0671DD;
    float: right;
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.edit-popup.modal .modal-dialog .modal-content{
    max-width: 460px;
}
.edit-popup.modal .modal-dialog .modal-content .modal-body .club-desc-row{
    max-width: 100%;
    flex: 0 0 100%;
    margin: 0 0 15px;
    font-size: 12px;
}
.edit-popup.modal .modal-dialog .modal-content .modal-body .club-desc-row label, .edit-popup.modal .modal-dialog .modal-content .modal-body .club-desc-row input{
    font-size: 12px;
}
.edit-popup.modal .modal-dialog .modal-content .modal-footer{
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 0;
}
.edit-popup.modal .modal-dialog .modal-content .modal-body .club-desc-row.profile-box input{
    border: none;
}
.edit-popup.modal .modal-dialog .modal-content .modal-body .edit-user-profile-image{
    border-radius: 50%;
    max-width: 60px;
    height: 60px;
    border: 1px solid #0671DD;
}
.modal .modal-dialog .modal-content .modal-body .profile-box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.modal .modal-dialog .modal-content .modal-body .profile-box .upload-btn{
    font-size: 12px;
    background: #0671DD;
    color: #fff;
    margin-left: 20px;
    font-weight: 600;
}
.modal .modal-dialog .modal-content .modal-body .profile-box .upload-btn svg{
    margin-right: 8px;
}
.main-right-section .main-right-inner-inner .footer-switch.edit{
    margin-right: 0;
}

.folder-parent p {
    color: #0671dd !important;  
}

.switch.btn.btn-xs{margin-right: 10px;   width: 130px;border-radius: 50px;}
.switch.btn.btn-xs .switch-group span.btn-xs{padding: 6.5px 20px;line-height: 16px;}
.switch.btn.btn-xs .switch-group span.btn-xs.btn-primary{border-color:#0671DD; background-color: #0671DD;}
.switch.btn.btn-xs .switch-group span.switch-off.btn{background: #fff;color: #0671dd;border-color: #0671dd;}
.switch.btn.btn-xs .switch-group span.switch-handle.btn.btn-lightbtn-xs{border-radius: 100%;}
.switch.btn.btn-xs.off .switch-group span.switch-handle.btn.btn-lightbtn-xs{background: #0671dd;}
.filter-select{min-width: 150px;height: 33px;}
.filter-select .css-xb97g8{display: none !important;}
.filter-select .css-1rhbuit-multiValue{background-color: transparent;}
.filter-select div.css-yk16xz-control,.filter-select div.css-1pahdxg-control{font-size:14px;height: 100%;min-height:33px;background-color: transparent;}
.filter-select .css-g1d714-ValueContainer{font-size:14px;height: 100%;background-color: transparent;}
.filter-select .css-1hb7zxy-IndicatorsContainer div{padding: 0 8px;}
.filter-select .css-26l3qy-menu{text-align: left;z-index: 9999;}
.filter-select .css-26l3qy-menu label{font-size: 14px;text-transform: capitalize;}
.filter-select input[type="checkbox"]{width: 11px;height: 11px;}
.filter-select .css-26l3qy-menu .css-4ljt47-MenuList{padding: 0;}
.filter-select .css-26l3qy-menu .css-4ljt47-MenuList div div{padding: 5px 7px;}
@media(max-width: 1600px){
    .main-section{height: auto;}
}


.folder-modal .modal-dialog .modal-content, .edit-popup .modal-dialog .modal-content, .download-modal .modal-dialog .modal-content{padding: 0;}
.folder-modal .modal-dialog .modal-content .modal-header, .edit-popup .modal-dialog .modal-content .modal-header, .download-modal .modal-dialog .modal-content .modal-header{display: flex; justify-content: center; background: #0671DD; padding: 5px 16px;}
.download-modal .modal-dialog .modal-content .modal-header .modal-title, .folder-modal .modal-dialog .modal-content .modal-header .modal-title{color: #fff;}
.download-modal .modal-dialog .modal-content .modal-header, .folder-modal .modal-dialog .modal-content .modal-header{padding-bottom: 10px !important; margin-bottom: 30px;}
.edit-popup .modal-dialog .modal-content .modal-header .btn-close, .folder-modal .modal-dialog .modal-content .modal-header .btn-close{position: absolute; left: 10px; filter: invert(1); opacity: 1;display: block !important;}
.edit-popup .modal-dialog .modal-content .modal-body{margin-top: 0px;}
.edit-popup .modal-dialog .modal-content .modal-footer{display: flex; justify-content: center; margin-top: 12px;}
.edit-popup .modal-dialog .modal-content .modal-header .modal-title{color: #fff;}
.download-modal .modal-header .btn-close{top: auto; left: 10px; right: auto; background-size: 17px; filter: invert(1); opacity: 1;}
.folder-modal .add-folder{position: absolute; right: 10px;}
.folder-modal .modal-dialog .modal-content .modal-footer{justify-content: center;}
.folder-modal .modal-dialog .modal-content .modal-footer .btn{margin-bottom: 0 !important;}

.sidebar .sidebar-footer{width: 100%; left: 0; bottom: 10px;}
.sidebar .sidebar-footer .old-session-planner-btn{width: calc(100% - 20px);}
.main-right-section .main-right-inner-inner .block-footer-dropdown{height: auto; position: absolute; top: 0; right: auto; left: 80%; background-color: #fff; z-index: 999; padding: 8px 0; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04); border-radius: 4px; min-width: 200px; border: none; width: 300px; border: 1px solid #e7e7e7;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon{flex-direction: column; align-items: flex-start;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .tooltip-parent{display: flex; justify-content: flex-start; align-items: center; gap: 6px; padding: 0 8px; margin: 0; width: 100%; height: 40px;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .tooltip-parent:hover{background-color: #40576d12; width: 100%;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .tooltip-parent .custom-tooltip{display: block; position: static; background-color: transparent; background: none; color: #000; font-size: 14px;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .tooltip-parent .custom-tooltip::before{display: none;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .tooltip-parent .move-folder{width: 22px;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .tooltip-parent svg{height: 22px; width: 22px;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .tooltip-parent .arrow{margin-left: auto;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .header{padding: 8px 0; max-width: 100%; width: 100%;}
.main-right-section .main-right-inner-inner .block-footer-dropdown .footer-icon .header h3{font-size: 16px; line-height: 30px; color: #000; font-weight: 600; margin: 0; white-space: nowrap; text-overflow: ellipsis; padding: 0 12px 10px; overflow: hidden; border-bottom: 1px solid #e7e7e7;}

#modal .modal-actions button.coach-notes-export{background-color: #00a2ff !important;}

/* newDropdownLayout */
.newDropdownLayout_Parent{padding-bottom: 62px; background-color: #fff; overflow: auto; height: auto; min-height: 150px; box-shadow: 0px 0px 14px 3px rgb(224 229 238 / 50%); border-radius: 6px; width: 100%;}
.newDropdownLayout_Parent .header{display: flex; align-items: center; justify-content: flex-start; padding: 16px;}
.newDropdownLayout_Parent .header h3{font-size: 18px; line-height: 30px; padding-left: 12px; color: #000; margin: 0; font-weight: 500;}
.newDropdownLayout_Parent .header .back{background-color: #0d121600; color: #000; border: none; outline: none; box-shadow: none; padding: 0;}
.newDropdownLayout_Parent .searcArea{position: relative; padding: 0 10px;}
.newDropdownLayout_Parent .searcArea .searchIcon{position: absolute; max-width: 24px; top: 50%; transform: translateY(-50%); left: 18px; color: #000;}
.newDropdownLayout_Parent .searcArea .search{font-size: 14px; height: 38px; width: 100%; padding-left: 38px; outline: none; box-shadow: none;}
.newDropdownLayout_Parent .folderList{padding-top: 16px; overflow: auto; max-height: 350px; height: 100%;}
.newDropdownLayout_Parent .folderList .folder{display: flex; align-items: stretch; justify-content: flex-start; padding: 6px 0; transition: all .2s; padding: 5px 16px;}
.newDropdownLayout_Parent .folderList .folder:hover{background-color: #e7e7e7;}
.newDropdownLayout_Parent .folderList .folder .folder-icon{display: flex; align-items: center; justify-content: flex-start;}
.newDropdownLayout_Parent .folderList .folder .folder-icon .icon{height: 32px; width: 32px; background-color: #e7e7e7; border-radius: 4px; display: flex; align-items: center; justify-content: center;}
.newDropdownLayout_Parent .folderList .folder .folder-details{padding-left: 12px; max-width: calc(100% - 50px);}
.newDropdownLayout_Parent .folderList .folder .folder-details .name{overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 14px; color: #000; font-weight: 300; margin: 0; text-align: left;}
.newDropdownLayout_Parent .folderList .folder .folder-details .name .meta-items{color: #0d1216b3; display: block;}
.newDropdownLayout_Parent .folderList .folder .arrow{margin-left: auto; display: flex; align-items: center; justify-content: flex-end;}
.newDropdownLayout_Parent .folderList .folder svg{color: #000; max-width: 24px; min-width: 24px;}
.newDropdownLayout_Parent .footerBtns{position: absolute; bottom: 0; left: 0; width: 100%; display: flex; align-items: center; justify-content: space-between; background-color: #fff; z-index: 1; padding: 10px; border-top: 1px solid #e7e7e7;}
.newDropdownLayout_Parent .footerBtns .btn{font-size: 14px; background-color: #fff; color: #000; white-space: nowrap; padding: 0 10px; height: 38px; line-height: 38px;}
.newDropdownLayout_Parent .footerBtns .btn:hover{background-color: #e7e7e7;}
.newDropdownLayout_Parent .footerBtns .btn.move{background-color: #0d6efd; color: #fff;}
.newDropdownLayout_Parent .footerBtns .btn.add{width: 100%; background-color: #0d6efd; color: #fff;}
.newDropdownLayout_Parent .footerBtns .btn.move:hover{background-color: #0b5ed7 !important;}
.newDropdownLayout_Parent .searcArea .search.folderNew{padding: 2px 12px;}
.error{color: red !important;}
/* newDropdownLayout */

/* Download dropdown */
.main-right-section .main-right-inner-inner .block-footer-dropdown #downloadBox{width: 100%; height: 260px;}
.main-right-section .main-right-inner-inner #downloadBox .dropdown-item img{max-width: 20px; height: auto;}
.main-right-section .main-right-inner-inner #downloadBox .dropdown-item .pdf-span,
.main-right-section .main-right-inner-inner #downloadBox .dropdown-item .img-span{display: flex; flex-direction: row; align-items: center; justify-content: flex-start; border: none; background-color: transparent; width: 100%;}
.main-right-section .main-right-inner-inner #downloadBox .dropdown-item p{padding-bottom: 0; color: #000;}
.main-right-section .main-right-inner-inner #downloadBox .footer-icon .header h3{width: 100%;}
.main-right-section .main-right-inner-inner #downloadBox .footer-switch{margin: 0;}

#selectBoxCustom{border: 1px solid #000; width: calc(100% - 15px); height: 40px; margin: auto; border-radius: 4px; display: flex; align-items: center; justify-content: flex-start;}
#downloadBox #selectBoxCustom + .downloadTypeList{position: absolute; height: auto; max-height: 320px; width: calc(100% - 15px); bottom: auto; top: auto; left: 7.5px; overflow: auto; opacity: 0; visibility: hidden; transition: .2s; padding: 8px 0 20px; border: 1px solid #e7e7e7; margin-top: 1px; background-color: #fff; border-radius: 4px; z-index: 2;}
#downloadBox #selectBoxCustom + .downloadTypeList.active{opacity: 1; visibility: visible;}
#downloadBox #selectBoxCustom + .downloadTypeList .downloadTypeListItems{height: 100%; overflow: auto;}
#downloadBox .download-subhead{font-size: 12px; font-weight: 600; color: #000; padding: 0 15px;}
#downloadBox #selectBoxCustom .arrow{margin-left: auto; color: #000; padding: 0 10px; display: flex; align-items: center; justify-content: center; height: 100%;}
#downloadBox #selectBoxCustom .arrow svg{transform: rotate(90deg);}
#downloadBox #selectBoxCustom .arrow:hover svg{color: #0671DD;}
#downloadBox .downloadBtnBox{position: absolute; top: auto; bottom: 0; left: auto; right: 0; padding: 10px; width: 100%;}
#downloadBox .downloadBtn{background-color: #0671DD; color: #fff; font-size: 12px; width: 100%;}
#downloadBox .defaultText{font-size: 14px; text-align: left;}
#downloadBox .disable{opacity: 0.5;}
/* Download dropdown */